.after-parallax{
    position: relative;
    z-index: 1010;
}
.parallax-window{
    min-height: 50px;
    //background: rgba(0,0,0,.4);
    position: relative;
    z-index: 1009;
    overflow: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0;
}
.parallax-intro{
   // background: rgba(0,0,0,.4);
  
    padding: 30px 0px 0px 0px;
    color: white;
    
    border: 0;
    font-size: 16px;
    font-weight: normal;
    h2.title{
        color: white;
        
        margin: 0 0 10px;
        padding: 0;
    }
}